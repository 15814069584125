<template>
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.8328 4.99967C11.8328 4.80957 11.7603 4.62725 11.6314 4.49282C11.5025 4.3584 11.3276 4.28288 11.1453 4.28288L3.17953 4.28288L6.13117 1.20545C6.25261 1.06957 6.31872 0.889847 6.31558 0.704148C6.31244 0.51845 6.24028 0.341273 6.11432 0.209944C5.98836 0.0786149 5.81843 0.00338789 5.64032 0.000111463C5.46221 -0.00316496 5.28984 0.0657649 5.15951 0.192379L1.03456 4.49314C0.905811 4.62754 0.833496 4.80972 0.833496 4.99967C0.833496 5.18963 0.905811 5.37181 1.03456 5.50621L5.15951 9.80697C5.28984 9.93358 5.46221 10.0025 5.64032 9.99924C5.81843 9.99596 5.98836 9.92073 6.11432 9.78941C6.24029 9.65808 6.31244 9.4809 6.31558 9.2952C6.31872 9.1095 6.25261 8.92978 6.13117 8.7939L3.17953 5.71647L11.1453 5.71647C11.3276 5.71647 11.5025 5.64095 11.6314 5.50652C11.7603 5.3721 11.8328 5.18978 11.8328 4.99967Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "CustomArrowRightIcon",
};
</script>
